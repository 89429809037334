<template>
  <div class="unsubscribe">
    <div class="content">
      <h1 class="unsubscribe__title">Благодарим за обращение!</h1>
      <p class="unsubscribe__description">
        Наш оператор свяжется с Вами в ближайшее время
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Unsubscribe",
  mounted() {
    console.log(window.location)
    setTimeout(() => {
      window.location = window.location.origin
    }, 3000)
  }
};
</script>

<style lang="scss" scoped>
.unsubscribe {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__title {
    margin-bottom: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 23px;
      line-height: 29px;
    }
  }

  &__description {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 32px;
    color: #1f1f1f;
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
}
</style>